import React from "react"
import { JsonLd } from "react-schemaorg";

export default function Breadcrumbs({
    breadcrumbs
}) {
    
    let crumbs = breadcrumbs.map((b, i) => { return {
        "@type": "ListItem",
        "position": i + 1,
        "name": b.title,
        "item": 'https://www.aktoploika.gr' + b.url
    }});

    return <JsonLd item={
        {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": crumbs
    }
    }/>;
    
};